import React, { useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import MediaPlayer from '~components/media-player';
import Container from '~components/Container';
import RichText from '~components/RichText';
import AnimatedPlayButton from './components/AnimatedPlayButton';

const useStyles = makeStyles(() => ({
  media: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0  auto'
  },
  containerSpacing: {
    paddingTop: '90px',
    paddingBottom: '90px'
  },
  featuredVideoSpacing: {
    marginTop: '28px',
    marginBottom: '20px'
  }
}));
const config = {
  wistia: {
    options: {
      seo: false,
      videoFoam: true,
      playlistLinks: 'auto',
      playerColor: '#3688FF',
      resumable: false,
      playButton: false
    }
  }
};

const AboutHero = ({
  hero_featured_video,
  hero_text,
  underline_text,
  hero_head_image,
  hero_background_image
}) => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleButtonVisibilityOnPlay = () => {
    setIsVisible(false);
  };
  const handleButtonVisibilityOnEnd = () => {
    setIsVisible(true);
    setIsPlaying(false);
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
  };

  return (
    <Container
      center
      background={`url(${hero_background_image && hero_background_image.url})`}
      className={classes.containerSpacing}
    >
      <img
        src={hero_head_image && hero_head_image.url}
        alt={hero_head_image && hero_head_image.alt}
        className={`${classes.media} lazyload`}
        style={{ marginBottom: '48px' }}
      />
      <RichText mode="aboutHeroText" html={hero_text && hero_text.html} verticalSpacing={0} />
      <Box position="relative" width="100%" className={classes.featuredVideoSpacing}>
        <Box maxWidth="600px" margin="0 auto">
          <MediaPlayer
            url={hero_featured_video && hero_featured_video.url}
            playing={isPlaying}
            config={config}
            onPlay={handleButtonVisibilityOnPlay}
            onEnded={handleButtonVisibilityOnEnd}
          />
        </Box>
        {isVisible && <AnimatedPlayButton onClick={handlePlayButtonClick} />}
      </Box>
      <RichText
        mode="aboutHeroText"
        html={underline_text && underline_text.html}
        verticalSpacing={0}
      />
    </Container>
  );
};

AboutHero.propTypes = {
  hero_text: PropTypes.shape({ html: PropTypes.string, text: PropTypes.string }),
  underline_text: PropTypes.shape({ html: PropTypes.string, text: PropTypes.string }),
  hero_head_image: PropTypes.shape({ url: PropTypes.string, alt: PropTypes.string }).isRequired,
  hero_background_image: PropTypes.shape({ url: PropTypes.string }).isRequired,
  hero_featured_video: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

AboutHero.defaultProps = {
  hero_text: null,
  underline_text: null
};

export default AboutHero;
